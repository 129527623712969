<template>
  <div>
    <vvo v-slot="v">
      <div class="row form-group">
        <div class="col-lg-12">
          <label>
            <trans>그룹명</trans>
          </label>
          <valid-input :vid="'그룹명'" :inputType="'text'" :classList="'form-control'" :inputValue.sync="newGroup.groupName"
            :placeholder="$t('그룹명을 입력하세요.')" :rules="rules.MENU_GROUP_NAME_RULE"
            :errorMessage="validMessage.MENU_GROUP_NAME_VALID_MESSAGE"></valid-input>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-8">
          <label>
            <trans>그룹 권한</trans>
          </label>
            <select class="form-control" v-model="newGroup.builtIn">
              <option value="Admin">Admin</option>
              <option value="Engineer">Engineer</option>
              <option value="Partner">Partner</option>
              <option value="User">User</option>
            </select>
  
        </div>
        <div class="col-lg-4 m-auto">
          <label class="m-auto"> &nbsp; </label>
          <div class="checkbox checkbox-css">
            <input type="checkbox" :id="'newGroupUse_' + newGroup.isUse" v-model="newGroup.isUse" />
            <label :for="'newGroupUse_' + newGroup.isUse">
              <trans>사용여부</trans>
            </label>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="w-100">
        <button class="btn btn-sm btn-danger" v-if="isUpdate" v-on:click="onDeleteGroup()"><i class="fa fa-times"></i> {{
          $t("삭제") }}</button>
        <button class="btn btn-sm btn-primary m-r-5 pull-right" @click="onValidate(v, onSaveGroup)"  ><i class="fa fa-save"></i> {{
          $t("저장") }}</button>
      </div>
    </vvo>
  </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts"
import { StatusCodes } from "http-status-codes";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  props: ["changeGroup", "save-done"],
  data() {
    return {
      isUpdate: false,
      newGroup: {},
      rules,
      validMessage
    };
  },
  watch: {
    changeGroup: function () {
      this.initSetting();
    },
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.newGroup = Object.assign({}, this.changeGroup);
      this.isUpdate = !this.isEmpty(this.newGroup.groupIdx);
    },
    async onSaveGroup() {

      try {
        if (this.isUpdate) {

          const popupResult = await this.alertQuestion(popupMessages.PERMISSION_GROUP_UPDATE_POPUP_MESSAGE);
          if (!popupResult.value)
            return;

          const result = await backEndApi.group.updateGroup(this.newGroup);
          const data = result.data;

          if (this.$err(data))
            return;

          this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
          this.$emit("save-done");

        } else {
          const popupResult = await this.alertQuestion(popupMessages.PERMISSION_GROUP_ADD_POPUP_MESSAGE);

          if (!popupResult.value)
            return;

          const result = await backEndApi.group.insertGroup(this.newGroup);
          const data = result.data;

          if (this.$err(data))
            return;

          this.alertNoti(popupMessages.PERMISSION_COMMON_REGIST_SUCCESS_POPUP_MESSAGE);
          this.$emit("save-done");
        }
      } catch (err) {

        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
          .createBizHandle(this.ERR_KIND.MISSING_ARGUMENT, popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
          .createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
    async onDeleteGroup() {

      const popupResult = await this.alertConfirmWarning(popupMessages.PERMISSION_GROUP_DELETE_POPUP_MESSAGE);
      if (!popupResult.value)
        return;

      try {
        const result = await backEndApi.group.deleteGroup(this.newGroup.groupIdx);
        const data = result.data;

        if (this.$err(data)) 
        return;

      this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
      this.$emit("save-done");

      } catch (err) {
            new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
          .createBizHandle(this.ERR_KIND.MISSING_ARGUMENT, popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
          .createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
          .errHandling();
      }            
    },
  },
};
</script>
