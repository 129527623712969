<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="pull-right" style="margin-top:-30px;">
					<button class="btn btn-xs btn-lime m-r-5" @click="openNewUserModal()" v-b-tooltip.hover :title="$t('사용자 등록')"><i class="fa fa-plus"></i></button>
					<button class="btn btn-xs btn-success m-r-15" @click="onRefresh()" v-b-tooltip.hover :title="$t('새로고침')"><i class="fa fa-redo"></i></button>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12" style="min-height:550px; max-height:550px;overflow-y:auto;overflow-x:hidden;">
				<vue-good-table
					v-bind="merge(GRID_OPT, { paginationOptions: { enabled: false } })"
					:columns="columns"
					:rows="users"
					:pagination-options="{
						enabled: true,
						nextLabel: $t('다음'),
						prevLabel: $t('이전'),
						ofLabel: '/',
						pageLabel: $t('페이지'), // for 'pages' mode
						rowsPerPageLabel: $t('표시 개수'),
					}"
				>
					<div slot="emptystate" style="text-align: center;">
						{{$t('조회된 결과가 없습니다.')}}
					</div>
					<template slot="table-row" slot-scope="props">
						<template v-if="props.column.field == 'userIdx'">
							<div class="checkbox checkbox-css m-l-30">
								<input type="checkbox" :checked="isCheckUser(props.row)" @click="onCheckUser(props.row, $event)" :id="'management_userTable' + props.row.userIdx" />
								<label :for="'management_userTable' + props.row.userIdx"></label>
							</div>
						</template>
						<template v-else-if="props.column.field == 'userId'">
							<a href="javascript:" @click="openUserModal(props.row.userId)"> {{ props.row.userId }}</a>
						</template>
						<template v-else-if="props.column.field == 'regDt'">
							{{ props.row.regDt.format("yyyy-MM-dd HH:mm") }}
						</template>
						<template v-else>{{ props.formattedRow[props.column.field] }} </template>
					</template>
				</vue-good-table>
			</div>
		</div>

		<b-modal ref="newUserModal" :hide-footer="true" :title="$t('새 사용자')">
			<user-detail :changeUser="{}" :readonly="false" @save-done="onNewUserSaveDone" :curGroupIdx="curGroupIdx" />
		</b-modal>

		<b-modal ref="userModal" :hide-footer="true" :title="$t('사용자 상세정보')">
			<user-detail
				:isChangePassword="true"
				:isShowDelete="true"
				:changeUser="changeUser"
				:readonly="false"
				@change-password="openChangePwdModal(changeUser)"
				:curGroupIdx="curGroupIdx"
				@save-done="onUserSaveDone"
			/>
		</b-modal>

		<b-modal ref="changePwdModal" :title="$t('비밀번호 재설정')" :hide-footer="true">
			<change-pwd-modal :changeUser="changeUser" @save-done="onChangePwdModalSaveDone" />
		</b-modal>
	</div>
</template>

<style scoped>
	/* 라인이 안맞아서 padding값 조정함 */
	.checkbox.checkbox-css {
		padding-top: 4px !important;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi.js";
	import UserDetail from "./UserDetail.vue";
	import ChangePwdModal from "./ChangePwdModal.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler";
	import { StatusCodes } from "http-status-codes";

	export default {
		props: ["curGroupIdx"],
		data() {
			return {
				changeUser: {}, // 모달창에서 사용할 사용자정보

				groupUsers: [], // 그룹내 사용자권한 목록
				users: [], // 그리드에 표시할 사용자 목록
				columns: [
					{
						label: global.xe.$t("허가"),
						field: "userIdx",
						type: "text",
						width: "7%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: global.xe.$t("사용자 아이디"),
						field: "userId",
						type: "text",
						width: "15%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: global.xe.$t("사용자명"),
						field: "userName",
						type: "text",
						width: "15%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: global.xe.$t("이메일"),
						field: "email",
						type: "text",
						width: "20%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: global.xe.$t("전화번호"),
						field: "phone",
						type: "text",
						width: "15%",
						tdClass: "text-left text-inverse text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: global.xe.$t("등록일"),
						field: "regDt",
						type: "text",
						width: "20%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap ",
					},
				],
			};
		},
		components: {
			UserDetail,
			ChangePwdModal,
		},
		watch: {
			curGroupIdx: function() {
				this.onRefresh();
			},
		},
		computed: {
			isCheckUser() {
				return (userInfo) => {
					return userInfo.groupIdx === null ? false : true;
				};
			},
		},
		mounted() {
			this.onRefresh();
		},

		methods: {
			// 모달창
			openNewUserModal() {
				this.$refs.newUserModal.show();
			},
			onNewUserSaveDone() {
				this.$refs.newUserModal.hide();
				this.onRefresh();
			},
			openUserModal(userId) {
				// 선택된 사용자 정보갱신 후 오픈..
				backEndApi.user.getUser(userId).then(({ data }) => {
					if (this.$err(data)) return;
					// console.log(data);
					this.changeUser = data;

					this.$refs.userModal.show();

					// this.alertNoti(`${popupMessages.PERMISSION_USER_DELETE_GROUP_SUCCESS_POPUP_MESSAGE}`);
					// this.onRefresh();
				});
			},
			// 사용자 비밀번호변경 모달 열기
			openChangePwdModal(user) {
				this.changeUser = user;
				this.$refs.changePwdModal.show();
			},
			onChangePwdModalSaveDone() {
				this.$refs.changePwdModal.hide();
			},
			async onRefresh() {
				await this.searchUser();
			},
			async searchUser() {
				this.users.clear();
				this.groupUsers.clear();

				let users = [];

				let result = await backEndApi.group.searchGroupUser(this.curGroupIdx);

				if (this.$err(result.data)) return;

				this.groupUsers.range(result.data);

				for (let i = 0; i < this.groupUsers.length; i++) {
					let result = await backEndApi.user.getUser(this.groupUsers[i].userId);

					if (this.$err(result.data)) return;

					users.push(result.data);
				}

				this.users = users;

				for (let i = 0; i < this.users.length; i++) {
					this.users[i].groupIdx = this.groupUsers.find((v) => v.userId === this.users[i].userId) ? this.groupUsers.find((v) => v.userId === this.users[i].userId).groupIdx : null;
				}

				// backEndApi.user.searchUser().then(({ data }) => {
				//   console.log(data);
				//   if (this.$err(data)) return;

				//   this.users.range(data);
				// });
			},
			onUserSaveDone() {
				this.$refs.userModal.hide();
				this.onRefresh();
			},

			async onCheckUser(user, evt) {
				evt.preventDefault();

				let isChecked = this.groupUsers.find((v) => v.userId == user.userId).groupIdx === null ? false : true;
				let groupUser = { groupIdx: this.curGroupIdx, userId: user.userId };

				if (!isChecked) {
					// groupUsers에 체크한 사용자가 없으면 추가
					let submit = await this.alertQuestion(popupMessages.PERMISSION_USER_ADD_GROUP_POPUP_MESSAGE);
					if (!submit.value) return;

					try {
						let result = await backEndApi.group.insertGroupUser(groupUser);

						if (!result) throw new Error("에러가 발생하였습니다.");
						this.alertNoti(popupMessages.PERMISSION_COMMON_USERGROUP_REGIST_SUCCESS_POPUP_MESSAGE);
						this.onRefresh();
					} catch (err) {
						new AppErrorHandler(err)
							.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR, popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.errHandling();
						return;
					}
				} else {
					let isCheck = await this.alertConfirmWarning(popupMessages.PERMISSION_USER_DELETE_GROUP_POPUP_MESSAGE);
					if (!isCheck.value) return;

					try {
						let result = await backEndApi.group.deleteGroupUser(groupUser);

						if (!result) throw new Error("에러가 발생하였습니다.");
						this.alertNoti(popupMessages.PERMISSION_COMMON_USERGROUP_DELETE_SUCCESS_POPUP_MESSAGE);
					} catch (err) {
						new AppErrorHandler(err)
							.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR, popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.errHandling();
						return;
					}

					this.onRefresh();
				}
			},
		},
	};
</script>
