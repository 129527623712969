<template>
  <div>
    <vvo v-slot="{ passes }">
      <div class="row form-group">
        <div class="col-9">
          <label>
            <trans>새 분석템플릿 코드</trans>
          </label>
          <valid-input :inputType="'text'" :classList="'form-control'" :inputValue.sync="newTemplateCode"
            :rules="rules.DASHBOARD_MANAGE_TEMPLATE_RULE"
            :errorMessage="validMessage.DASHBOARD_MANAGE_TEMPLATE_VALID_MESSAGE"></valid-input>
        </div>
        <div class="col-3">
          <label>&nbsp;</label>
          <div class="checkbox checkbox-css">
            <input type="checkbox" :id="'management_userTable'" v-model="isClearPtAddr" />
            <label :for="'management_userTable'">
              <trans>관제점 지우기</trans>
            </label>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="w-100">
        <button class="btn btn-sm btn-primary m-r-5 pull-right" @click="passes(onSaveTemplate)"  ><i class="fa fa-save"></i>
          {{ $t("저장") }}</button>
      </div>
    </vvo>
  </div>
</template>

<script>
import backEndApi from "../../../../api/backEndApi.js";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts"

export default {
  props: ["viewSet"],
  data() {
    return {
      newTemplateCode: "",
      isClearPtAddr: false,
      rules,
      validMessage
    };
  },
  methods: {
    onSaveTemplate() {
      this.alertQuestion(popupMessages.DASHBOARD_SAVE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        if (this.isClearPtAddr) {
          // 관제점 주소 초기화
          this.updateObject(
            this.viewSet,
            function (key) {
              return key === "point";
            },
            { key: "point", value: null }
          );
          this.updateObject(
            this.viewSet,
            function (key) {
              return key === "label";
            },
            { key: "label", value: null }
          );
          this.updateObject(
            this.viewSet,
            function (key) {
              return key === "afterAction";
            },
            { key: "afterAction", value: null }
          );
          this.updateObject(
            this.viewSet,
            function (key) {
              return key === "afterBind";
            },
            { key: "afterBind", value: null }
          );
        }

        let param = {
          templateCode: this.newTemplateCode,
          option: this.viewSet,
        };

        backEndApi.visual.saveVisualTemplate(param).then(({ data }) => {
          if (this.$err(data)) {
            // TODO: 어떤 오류인지 알 수 없는데..
            //this.notiBottom("이미 존재하는 코드입니다. 다시 시도해주세요.");
            return;
          }

          this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
          this.$emit("save-done");
        });
      });
    },
    // 관제점 주소 초기화
    updateObject(obj, predicate, opt) {
      for (let p in obj) {
        if (typeof obj[p] == "object") {
          this.updateObject(obj[p], predicate, opt);
        } else if (predicate(p, obj[p])) {
          obj[opt.key] = opt.value;
        }
      }
    },
  },
};
</script>
