<template>
	<div>
			<!--
     <title-icon :title="$t('대시보드관리')" icon="fa-chart-line" :col="6">
      <ln-button :label="$t('새로고침')" color="primary" icon="fa-redo" :tooltip="$t('새로고침')" @click="initSetting()" size="default" />
      <ln-button :label="$t('추가')" color="default" icon="fa-plus" :tooltip="$t('대시보드 추가')" @click="openNewAnalysisModal()" size="default" />
    </title-icon> -->
			<div class="d-flex flex-row">
				<div class="col-lg-10 p-l-0 p-r-0">
					<div class="pull-left">
						<div class="h3">
							{{$t('시스템 관리')}} > {{$t('대시보드 관리')}}
						</div>
					</div>
				</div>

				<div class="btn-group m-l-auto">
					<!-- <ln-button :label="$t('새로고침')" color="primary" icon="fa-redo" :tooltip="$t('새로고침')" @click="initSetting()" size="default" />
        <ln-button :label="$t('추가')" color="default" icon="fa-plus" :tooltip="$t('대시보드 추가')" @click="openNewAnalysisModal()" size="default" /> -->
					<button class="btn btn-primary m-r-5 m-l-auto" @click="initSetting()" v-b-tooltip.hover :title="$t('새로고침')"><i class="fa fa-redo"></i> {{ $t("새로고침") }}</button>
					<button class="btn btn-default m-l-auto" @click="openNewAnalysisModal()" v-b-tooltip.hover :title="$t('추가')"><i class="fa fa-plus"></i> {{ $t("추가") }}</button>
				</div>
			</div>
			<hr style="margin: 10px 0px 10px 0px" />

			<div class="row">
				<div class="col-12">
					<vue-good-table
						v-bind="Object.assign(GRID_OPT, { lineNumbers: true })"
						:columns="columns"
						:rows="anaysisRows"
						:pagination-options="{
							enabled: true,
							nextLabel: $t('다음'),
							prevLabel: $t('이전'),
							ofLabel: '/',
							pageLabel: $t('페이지'), // for 'pages' mode
							rowsPerPageLabel: $t('표시 개수'),
						}"
					>
						<div slot="emptystate" style="text-align: center;">
							{{$t('조회된 결과가 없습니다.')}}
						</div>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'name' && props.column.label !== 'Action'">
								<b class="p-l-10"> {{ props.row.name }} </b>
								<b-dropdown variant="default" toggle-class="btn-white btn-xs" class="pull-right">
									<b-dropdown-item href="#" @click="openSaveAsModal(props.row)"> {{ $t("대시보드 다른 이름으로 저장") }}</b-dropdown-item>
									<b-dropdown-item href="#" @click="openSaveAsNewTemplateModal(props.row)"> {{ $t("템플릿으로 저장") }}</b-dropdown-item>
									<b-dropdown-divider></b-dropdown-divider>
									<b-dropdown-item href="#" @click="onDeleteAnalysis(props.row)"> {{ $t("삭제") }}</b-dropdown-item>
								</b-dropdown>
							</span>
							<span v-else-if="props.column.label == $t('Action')">
								<router-link :to="'/visual/analysis/' + props.row.name" target="_blank" class="text-inverse" v-b-tooltip.hover :title="$t('새 탭에서 보기')">
									<i class="fa fa-external-link-alt"></i>
								</router-link>
							</span>
							<span v-else-if="props.column.field == 'modefied'">
								{{ props.row.modefied || props.row.birth }}
							</span>
							<span v-else-if="props.column.field == 'inMenus'" class="ellipsis-span">
								{{ props.formattedRow[props.column.field] }}
							</span>
							<span v-else>{{ props.formattedRow[props.column.field] }}</span>
						</template>
					</vue-good-table>
				</div>
			</div>

			<b-modal ref="newAnalysisModal" :hide-footer="true" :title="$t('대시보드 관리 추가')">
				<analysis-detail @save-done="onNewAnalysisSaveDone"></analysis-detail>
			</b-modal>

			<b-modal ref="saveAsModal" :hide-footer="true" :title="$t('대시보드 관리 다른 이름으로 저장')">
				<div>
					<vvo v-slot="{ passes }">
						<div class="row form-group">
							<div class="col-md-12">
								<label><trans>저장할 새 대시보드 제목</trans></label>
								<valid-input
									:inputType="'text'"
									:classList="'form-control'"
									:inputValue.sync="saveAsViewCode"
									:rules="rules.DASHBOARD_MANAGE_NEW_NAME_RULE"
									:errorMessage="validMessage.DASHBOARD_MANAGE_NEW_NAME_VALID_MESSAGE"
								></valid-input>
							</div>
						</div>
						<div slot="modal-footer" class="w-100">
							<button class="btn btn-sm btn-primary m-r-5 pull-right" @click="passes(onSaveAsAnalysis)"  ><i class="fa fa-save"></i> {{ $t("저장") }}</button>
						</div>
					</vvo>
				</div>
			</b-modal>

			<b-modal ref="saveAsNewTemplateModal" :hide-footer="true" :title="$t('분석템플릿으로 저장')">
				<template-detail :viewSet="viewSet" @save-done="onSaveAsNewTemplateSaveDone"></template-detail>
			</b-modal>
	</div>
</template>

<style scoped>
	.radio.radio-css label {
		display: block !important;
	}
	/* 라인이 안맞아서 padding값 조정함 */
	.radio.radio-css {
		padding-top: 4px !important;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi.js";
	import analysisDetail from "./visual/AnalysisDetail.vue";
	import templateDetail from "./visual/TemplateDetail.vue";
	import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts"

	export default {
		props: [],
		data() {
			return {
				newAnalysis: {},
				saveAsViewCode: "",
				viewSet: null,

				menus: [], // 메뉴에서 사용중인...

				anaysisRows: [],

				columns: [
					{
						label: this.$t("대시보드 제목"),
						field: "name",
						type: "text",
						width: "30%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap",
					},
					// {
					//   label: this.$t("동작"),
					//   field: "name",
					//   type: "text",
					//   width: "10%",
					//   tdClass: "text-center text-nowrap xe-grid-text",
					//   thClass: "text-center text-nowrap ",
					// },
					{
						label: this.$t("최종수정일"),
						field: "modefied",
						type: "text",
						width: "20%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("사용중인메뉴"),
						field: "inMenus",
						type: "text",
						//width: "10%",
						tdClass: "text-left text-nowrap xe-grid-text text-ellipsis",
						thClass: "text-center text-nowrap ",
					},
				],
				rules,
				validMessage
			};
		},
		components: {
			analysisDetail,
			templateDetail,
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				backEndApi.menu.searchMenuTree().then(({ data }) => {
					console.log(data);
					if (this.$err(data)) return;

					this.menus.range(data);

					// data:      [{"name":"homeplus-absorption-chiller02","size":8595,"birth":"2020-02-11 16:14:19","modefied":"2020-02-11 16:14:19"}]
					backEndApi.visual.searchViewSet().then(({ data }) => {
						if (this.$err(data)) return;

						this.anaysisRows.range(
							data.map((v) => {
								v.inMenus = this.findMenuByViewCode(v.name)
									.map((v) => v.title)
									.join(",");


								if (!this.isEmpty(v.modefied)) v.modefied = new Date(v.modefied).format("yyyy-MM-dd HH:mm");
								if (!this.isEmpty(v.birth)) v.birth = v.birth.format("yyyy-MM-dd HH:mm");
								return v;
							})
						);
					});
				});
			},
			getViewSet(page) {
				// TODO: viewSet을 갱신하지 말고, 서버에서 이전코드, 새코드 두개를 받아서 서버에서 처리해야 함...

				backEndApi.visual.getViewSet(page.name).then((res) => {
					if (this.$err(res.data)) return;

					this.viewSet = res.data;
				});
			},
			// 대시보드 추가
			openNewAnalysisModal() {
				this.$refs.newAnalysisModal.show();
			},
			onNewAnalysisSaveDone() {
				this.$refs.newAnalysisModal.hide();
				this.initSetting();
			},
			// 새 이름으로 저장
			openSaveAsModal(page) {
				this.getViewSet(page);
				this.$refs.saveAsModal.show();
			},
			onSaveAsAnalysis() {
				this.alertQuestion(popupMessages.DASHBOARD_SAVE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					let param = { viewCode: this.saveAsViewCode, option: this.viewSet };

					backEndApi.visual.saveAsViewSet(param).then(({ data }) => {
						if (this.$err(data)) return this.alertNoti(popupMessages.DASHBOARD_DATA_DUP_ERROR_POPUP_MESSAGE);

						this.$refs.saveAsModal.hide();
						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.initSetting();
					});
				});
			},
			// 새로운 템플릿 추가
			openSaveAsNewTemplateModal(page) {
				this.getViewSet(page);
				this.$refs.saveAsNewTemplateModal.show();
			},
			onSaveAsNewTemplateSaveDone() {
				this.$refs.saveAsNewTemplateModal.hide();

				this.initSetting();
			},
			onDeleteAnalysis(row) {
				if (row.inMenus) {
					this.alertNoti(popupMessages.DASHBOARD_USGING_ERROR_POPUP_MESSAGE);
					return;
				}

				this.alertConfirmWarning(popupMessages.DASHBOARD_DELETE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.visual.deleteViewSet({ viewCode: row.name }).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
						this.initSetting();
						this.$emit("init-menu");
					});
				});
			},
			findMenuByViewCode(viewCode) {
				// 대시보드가 메뉴에서 사용중인지 여부
				return this.menus
					.mapTree((v) => {
						if (v.resourceType == "analysis" && v.resourceKey == viewCode) return v;
					})
					.filter((v) => v);
			},
		},
	};
</script>
