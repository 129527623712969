<template>
  <div>
    <panel :variant="panelVariant" bodyClass="p-0" hideExpand="true" hideReload="true" hideCollapse="true" hideRemove="true" :isHoverButton="false">
      <template slot="header">
        <span class="panel-title"><trans>그룹 추가</trans></span>
      </template>
      <template slot="button">
        <button class="btn btn-xs btn-lime" @click="openGroupModal()" v-b-tooltip.hover :title="$t('그룹 추가')"><i class="fa fa-plus"></i> </button>
      </template>

      <div class="row xe-insert-card">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap xe-insert-card">
            <div class="xe-deck-card" v-for="(group, i) in groupDisableAuth" :key="i" :class="[group.groupIdx === changeGroup.groupIdx ? 'bg-primary text-white' : 'bg-white']">
              <div class="xe-deck-button">
                <i class="fa fa-pencil-alt pull-right p-10 pointer-cursor" @click="openGroupModal(group.groupIdx)"></i>
              </div>
              <div class="card-body">
                <div class="row pointer-cursor" style="width:100%" @click="selectGroup(group.groupIdx)">
                  <div class="col-lg-4">
                    <div v-if="group.isUse" class="image image-icon bg-silver text-center xe-deck-circle">
                      <i class="fa fa-users fa-fw"></i>
                    </div>
                    <div v-else class="image image-icon bg-silver text-center xe-deck-square">
                      <span class="fa-stack fa-2x">
                        <i class="fa fa-users fa-fw fa-stack-1x "></i>
                        <i class="fa fa-ban fa-fw fa-stack-2x " style="margin-top:-2px; font-size: 2em;"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <h5 class="card-title">{{ group.groupName }}</h5>
                    <span class="card-text">{{ group.builtIn }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>

    <b-modal ref="groupModal" :hide-footer="true" :title="$t('그룹')">
      <group-detail :changeGroup="changeGroup" :readonly="false" @save-done="onGroupSaveDone()" />
    </b-modal>
  </div>
</template>

<style scoped>
.xe-deck-button {
  opacity: 0;
}
.xe-insert-button {
  opacity: 0;
}
.xe-deck-card:hover .xe-deck-button {
  opacity: 1;
}
.xe-insert-card:hover .xe-insert-button {
  opacity: 1;
}

.xe-deck-card {
  width: 245px;
  margin: 10px;
  border: 1px solid #c1ccd1;
}

.xe-deck-circle {
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.xe-deck-square {
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.xe-deck-circle i,
.xe-deck-square i {
  font-size: 26px;
  padding-top: 10px;
  margin-top: 0px;
  /* color: #e8ebef; */
  color: #1f2225;
}

.xe-deck-circle span i,
.xe-deck-square span i {
  font-size: 26px;
  padding-top: 0px;
  margin-left: -8px;
  margin-top: 0px;
  /* color: #e8ebef; */
  color: #1f2225;
}

.xe-deck-bg-gray {
  background: rgba(182, 194, 201, 0.6) !important;
}
</style>

<script>
import Vue from "vue";
import backEndApi from "@api/backEndApi.js";
import GroupDetail from "./GroupDetail.vue";

export default {
  components: {
    GroupDetail,
  },
  data() {
    return {
      groups: [],
      changeGroup: {}, //그룹수정
    };
  },
  computed: {
    //그룹리스트에서 Super 권한 제거
    groupDisableAuth: function() {
      return this.groups.filter((i) => i.builtIn != "Super");
    },
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      backEndApi.group.searchGroup().then(({ data }) => {
        if (this.$err(data)) return;

        ////////////////////////////////////////////////////////////
        // 로직 : 로그인한 사용자의 권한보다 높은 권한의 그룹은 보이면 안된다.
        this.groups = data
          .map((group) => {
            if (this.loginLevels.find((lvl) => lvl <= Vue.GROUP_LEVELS[group.builtIn])) return group;
          })
          .filter((v) => v);

        ////////////////////////////////////////////////////////////
        // 그룹목록에서 초기값 바인딩
        if (this.isEmpty(this.changeGroup.groupIdx)) {
          if (this.groups.length > 0) {
            let obj = this.groups[0];
            if (obj) this.selectGroup(obj.groupIdx);
          }
        }
      });
    },
    //그룹 선택
    selectGroup(groupIdx) {
      backEndApi.group.getGroup(groupIdx).then(({ data }) => {
        if (this.$err(data)) return;

        this.changeGroup = data;

        this.$emit("select-group", this.changeGroup);
      });
    },
    // group 관련 모달
    openGroupModal(groupIdx) {
      if (this.isEmpty(groupIdx)) {
        this.changeGroup = {};
      } else {
        backEndApi.group.getGroup(groupIdx).then(({ data }) => {
          if (this.$err(data)) return;

          this.changeGroup = Object.assign({}, data);
        });
      }
      this.$refs.groupModal.show();
    },
    onGroupSaveDone() {
      this.$refs.groupModal.hide();
      this.initSetting();
    },
  },
};
</script>
