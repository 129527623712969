<template>
  <div class="m-r-15">
    <title-icon :title="$t('스케쥴 작업')" icon="fa-list" />

    <div class="form-inline m-b-10">
      <!-- <ln-select label="이벤트 유형" v-model="condition.eventType" :items="eventTypes" />
      <ln-select label="어플리케이션" v-model="condition.appId" :items="appIds" />
      <ln-picker label="시작일" v-model="condition.sDt" :setting="{ format: 'YYYY-MM-DD HH:mm' }" />
      <ln-picker label="종료일" v-model="condition.eDt" :setting="{ format: 'YYYY-MM-DD HH:mm' }" /> -->
      <ln-textin label="검색어" v-model="condition.scheduleName" placeholder="검색어" @keydown.enter="bindScheduleJob()" />
      <ln-button label="조회" color="primary" icon="fa-search" :tooltip="$t('조회하려면 클릭하세요.')" @click="bindScheduleJob()" />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <vue-good-table ref="grid" :columns="columns" :rows="scheduleJobs" v-bind="GRID_OPT">
          <div slot="emptystate" style="text-align: center;">
            조회된 결과가 없습니다.
          </div>
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field == 'subject'">
              {{ props.formattedRow[props.column.field] }}
            </template>
            <!-- <template v-else-if="props.column.field == 'Date'">
              {{ props.row.regDt ? props.row.regDt.format("yyyy-MM-dd") : "" }}
            </template>
            <template v-else-if="props.column.field == 'passed'">
              {{ props.row.regDt.passedTime() }}
            </template>
             -->
            <span v-else-if="props.column.field == 'action'" class="ellipsis-span" v-b-tooltip.hover :title="$t('지금 실행')">
              <a href="javascript:;" class="text-dark" @click="onRunJob(props.row.name)">
                <i class="fa fa-play" />
              </a>
            </span>
            <template v-else-if="props.column.field == 'updDt'">
              {{ props.row.updDt ? props.row.updDt.format("yyyy-MM-dd HH:mm:ss") : "" }}
            </template>
            <template v-else-if="props.column.field == 'lastRun'">
              {{ props.row.lastRun ? props.row.lastRun.format("yyyy-MM-dd HH:mm:ss") : "" }}
            </template>
            <template v-else-if="props.column.field == 'passed'">
              {{ props.row.lastRun ? props.row.lastRun.passedTime() : "" }}
            </template>
            <span v-else-if="props.column.field == 'triggers'" class="ellipsis-span">
              <table class="w-100">
                <tr v-for="(trigger, i) in props.row.triggers" :Key="i">
                  <td class="text-right" style="width:33%">{{ trigger.interval }}</td>
                  <td style="width:33%">{{ trigger.intervalType }}</td>
                  <td class="text-center" style="width:33%">{{ trigger.enable ? $t("사용중") : "" }}</td>
                </tr>
              </table>
            </span>
            <template v-else>{{ props.formattedRow[props.column.field] }}</template>
          </template>
        </vue-good-table>
      </div>

      <!-- TODO: 상세보기 팝업 있어야 함. -->
    </div>

    <!-- <schedule-job-popup ref="scheduleJobModal" :scheduleJobIdx="curScheduleJobName" @save-done="closeScheduleJobModal" /> -->

    <!-- <pre>
      scheduleJobs: {{ scheduleJobs }}
    </pre> -->
  </div>
</template>

<style scoped>
/* grid layout */
.panel-body {
  padding: 15px;
}
table.vgt-table td {
  border-right: 1px solid #dcdfe6;
}

.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 0;
}
</style>

<script>
import backEndApi from "@api/backEndApi.js";
import * as popupMessages from "@src/consts/popupMessageConsts";

//import ScheduleJobPopup from "./scheduleJob/ScheduleJobPopup.vue";

export default {
  components: {
    //ScheduleJobPopup
  },
  data() {
    return {
      condition: {
        sDt: new Date().format("yyyy-MM-dd 00:00"),
        eDt: new Date().format("yyyy-MM-dd 23:59"),
        searchText: "",
        group: "",
        scheduleName: "",
      },

      // grid data
      columns: [
        {
          label: this.$t("구분"),
          field: "group",
          type: "text",
          width: "10%",
          tdClass: "text-center text-nowrap xe-grid-text",
          thClass: "text-center text-nowrap ",
        },
        {
          label: this.$t("작업명"),
          field: "name",
          type: "text",
          width: "15%",
          tdClass: "text-left text-nowrap xe-grid-text",
          thClass: "text-center text-nowrap ",
        },
        {
          label: this.$t("경과시간"),
          field: "passed",
          type: "text",
          width: "10%",
          tdClass: "text-center text-nowrap xe-grid-text",
          thClass: "text-center text-nowrap ",
        },
        {
          label: this.$t("반복주기"),
          field: "triggers",
          type: "text",
          width: "15%",
          tdClass: "text-left text-nowrap xe-grid-text text-ellipsis",
          thClass: "text-center text-nowrap ",
        },
        {
          label: this.$t("동작"),
          field: "action",
          type: "text",
          width: "5%",
          tdClass: "text-center text-nowrap xe-grid-text",
          thClass: "text-center text-nowrap ",
        },
        {
          label: this.$t("최종실행일"),
          field: "lastRun",
          type: "text",
          width: "10%",
          tdClass: "text-center text-nowrap xe-grid-text",
          thClass: "text-center text-nowrap ",
        },
        {
          label: this.$t("최종수정일"),
          field: "updDt",
          type: "text",
          width: "10%",
          tdClass: "text-center text-nowrap xe-grid-text",
          thClass: "text-center text-nowrap ",
        },
        {
          label: this.$t("설명"),
          field: "remark",
          type: "text",
          width: "30%",
          tdClass: "text-left text-nowrap xe-grid-text text-ellipsis",
          thClass: "text-center text-nowrap ",
        },
      ],

      scheduleJobs: [],

      curScheduleName: "",
      popupMessages
    };
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      // this.bindScheduleJob();
    },
    bindScheduleJob() {
      this.scheduleJobs.clear();
      backEndApi.scheduleJob.getJobs().then(({ data }) => {
        if (this.$err(data)) return;

        this.scheduleJobs.range(data.ascBy("group", "name"));
      });
    },
    onRunJob(scheduleName) {
      this.alertQuestion(popupMessages.SYSTEM_EVENT_LOG_SCHEDULE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.scheduleJob.runJob(scheduleName).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.SYSTEM_EVENT_LOG_SCHEDULE_SUCCESS_POPUP_MESSAGE);
          this.bindScheduleJob();
        });
      });
    },
    // openScheduleJobModal(row){
    //   this.curScheduleJobName = row.scheduleJobIdx;
    //   this.$refs.scheduleJobModal.show();
    // },
    // closeScheduleJobModal(){
    //   this.$refs.scheduleJobModal.hide();
    // }
  },
};
</script>
