<template>
	<vvo v-slot="v">
		<div>
			<div v-if="parentMenu && parentMenu.menuIdx && isEmpty(newMenu.menuIdx)" class="alert alert-secondary fade show">'{{ parentMenu.title }}' {{ $t("메뉴의 하위에 추가됩니다.") }}</div>
			<div class="row form-group">
				<div class="col-md-12">
					<label>
						<trans>제목</trans>
					</label>
					<valid-input
						:vid="'제목'"
						:inputType="'text'"
						:classList="'form-control'"
						:inputValue.sync="newMenu.title"
						:rules="rules.MENU_NAME_RULE"
						:placeholder="$t('제목을 입력하세요.')"
						:errorMessage="validMessage.MENU_NAME_VALID_MESSAGE"
					></valid-input>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-12">
					<label>
						<trans>아이콘</trans>
					</label>
					<icon-select :current="newMenu.icon" @on-change="onChangeIcon" />
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-4">
					<label>
						<trans>리소스 유형</trans>
					</label>
					<select class="form-control" v-model="newMenu.resourceType">
						<option :value="''"> {{ $t("URL") }} </option>
						<option :value="'analysis'"> {{ $t("대시보드") }} </option>
						<option :value="'bbs'"> {{ $t("게시판") }} </option>
					</select>
				</div>
				<div class="col-md-8" v-if="newMenu.resourceType == ''">
					<label>
						<trans>경로</trans>
					</label>
					<div class="input-group">
						<valid-input
							:inputType="'text'"
							:classList="'form-control'"
							:placeholder="$t('경로를 입력하세요.')"
							:inputValue.sync="newMenu.path"
							@changeMethod="onChangePath($event.target.value)"
							:rules="rules.MENU_RESOURCE_URL_RULE"
							:NonStatus="true"
						></valid-input>
						<span class="input-group-append">
							<span class="input-group-text" v-b-tooltip.hover :title="$t('새 탭에서 보기')">
								<router-link :to="{ path: newMenu.path }" target="_blank" class="text-black">
									<i class="fa fa-external-link-alt"></i>
								</router-link>
							</span>
						</span>
					</div>
				</div>

				<div class="col-md-8" v-else-if="newMenu.resourceType !== ''">
					<label>
						<trans>리소스 키</trans>
					</label>
					<tree-select
						v-model="newMenu.resourceKey"
						:load-options="loadOptions"
						:placeholder="controlMessages.RESOURCE_KEY_TREE_PLACEHOLDER_MESSAGE"
						:noChildrenText="controlMessages.RESOURCE_KEY_TREE_NOCHILDERN_MESSAGE"
						:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
						:multiple="false"
						:always-open="false"
						:clearable="false"
						:auto-load-root-options="false"
						:options="resourceList"
						:disabled="isDisabled"
					/>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-4">
					<label>
						<trans>대상 디바이스</trans>
					</label>
					<input type="text" class="form-control" :value="newMenu.targetClient" disabled />
				</div>
				<div class="col-md-3">
					<label>
						<trans>표시 순서</trans>
					</label>
					<valid-input
						:vid="'표시 순서'"
						:inputType="'number'"
						:classList="'form-control'"
						:placeholder="$t('숫자를 입력하세요.')"
						:inputValue.sync="newMenu.displayOrder"
						:NonStatus="true"
						:rules="rules.MENU_VIEW_ORDER_RULE"
						:errorMessage="validMessage.MENU_VIEW_ORDER_VALID_MESSAGE"
					></valid-input>
					<!-- <input type="number" class="form-control" :class="classes" :placeholder="$t('숫자를 입력하세요.')"
              v-model="newMenu.displayOrder" /> -->
				</div>
				<div class="col-md-3 m-auto">
					<label class="m-auto"> &nbsp; </label>
					<div class="checkbox checkbox-css">
						<input type="checkbox" :id="chkMenuUseId" v-model="newMenu.isUse" />
						<label :for="chkMenuUseId">
							<trans>사용여부</trans>
						</label>
					</div>
				</div>
			</div>
			<!-- 지원 언어로 라벨명 변경했으나 어색해서 일단 주석 처리 -->
			<!-- <div class="row form-group">
				<div class="col-md-12">
					<label class="m-auto">
						<trans>지원 언어</trans>
					</label>
				</div>
			</div>
			<template v-for="(trans, i) in transStrs">
				<div class="row form-group" :key="i">
					<div class="col-md-2"><span class="flag-icon" :class="{ ['flag-icon-' + trans.flag]: true }" :title="trans.locale"></span> {{ trans.code }}</div>
					<div class="col-md-10">
						<valid-input :inputType="'text'" :classList="'form-control'" :inputValue.sync="trans.transStr" :NonStatus="true" :rules="rules.MENU_LANG_RULE"></valid-input>
					</div>
				</div>
			</template> -->

			<div slot="modal-footer" class="w-100 m-t-30">
				<button class="btn btn-sm btn-primary m-r-5 pull-right" @click="onValidate(v, onSaveMenu)"><i class="fa fa-save"></i> {{ $t("저장") }}</button>
				<button class="btn btn-sm btn-danger" v-if="isUpdate" @click="onDeleteMenu()"><i class="fa fa-times"></i> {{ $t("삭제") }}</button>
			</div>
		</div>
	</vvo>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		props: ["changeMenu", "parentMenu", "targetClient", "groupMenus", "curGroupIdx", "save-done"],
		data() {
			return {
				isUpdate: false,
				newMenu: {
					resourceType: "",
					resourceKey: null,
				},
				analysisList: [],
				bbsList: [],
				chkMenuUseId: this.getRandomId("newMenuUseId"),

				transStrs: [],
				rules,
				validMessage,
				controlMessages,
			};
		},
		watch: {
			changeMenu: function() {
				this.initSetting();
			},
			"newMenu.resourceType"() {
				// input text에 데이터 초기화
				if (this.isEmpty(this.newMenu.resourceType)) this.newMenu.resourceKey = null;
				this.newMenu.path = "";
			},
			"newMenu.resourceKey"() {
				switch (this.newMenu.resourceType) {
					case "analysis":
						this.newMenu.path = "/visual/analysis/" + this.newMenu.resourceKey;
						break;
					case "bbs":
						this.newMenu.path = "/board/boardList/" + this.newMenu.resourceKey;
						break;
					// default:
					//   this.newMenu.path = "";
				}
			},
			"newMenu.title": function(newV, oldV) {
				this.transStrs.map((v) => {
					// 키 업데이트
					v.transKey = newV;
					// 비어있거나, 문자열이 동일하면 동일하게 ....
					if (this.isEmpty(v.transStr) || v.transStr == oldV) v.transStr = newV;
				});
			},
			"newMenu.path": function(newV, oldV) {
				newV, oldV;
				this.newMenu.path;  
			},
		},
		computed: {
			nations() {
				return this.$store.getters.master.countryCodes;
			},
      nationList(){
        return this.$store.state.nations;
      },
			resourceList() {
				if (this.newMenu.resourceType == "analysis") return this.analysisList;
				else if (this.newMenu.resourceType == "bbs") return this.bbsList;
				else return null;
			},
			isDisabled() {
				if (this.newMenu.resourceType == "") return true;
				else return false;
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.isUpdate = !this.isEmpty(this.changeMenu.menuIdx);

				if (this.isUpdate) {
					this.newMenu = Object.assign({}, this.changeMenu);
				} else {
					this.newMenu["masterCode"] = this.$store.getters.master.masterCode;
					this.newMenu["targetClient"] = this.targetClient || "PC";
					this.newMenu["displayOrder"] = 9999;
					this.newMenu["isUse"] = 1;
				}

				if (this.isEmpty(this.newMenu.resourceType)) this.newMenu.resourceType = "";

				// 리소스 선택 - 대시보드 목록
				this.analysisList.clear();
				backEndApi.visual.searchViewSet().then(({ data }) => {
					if (this.$err(data)) return;

					this.analysisList.range(
						data.map((page) => {
							return {
								id: page.name,
								label: page.name,
							};
						})
					);
				});

				// 리소스 선택 - 게시판 목록
				this.bbsList.clear();
				// backEndApi.bbs.searchBbs().then(({ data }) => {
				//   if (this.$err(data)) return;

				//   if (!this.isEmpty(data) && Array.isArray(data)) {
				//     this.bbsList.range(
				//       data.forEach((bbs) => {
				//         return {
				//           id: bbs.bbsIdx,
				//           label: bbs.bbsName,
				//         };
				//       })
				//     );
				//   }
				// });

				// 다국어 준비
				if (this.newMenu.menuIdx) {
					this.transStrs.clear();
					backEndApi.trans.searchTransByResourceKey("menu", this.newMenu.menuIdx).then(({ data }) => {
						if (this.$err(data)) return;

            

						let list = this.nations.map((v) => {
							let trans = {
								transKey: "",
								locale: v.locale,
								transStr: "",
								status: "Done",
								reqDt: new Date().format("yyyyMMddHHmmss"),
								reqUserId: this.$store.getters.loginUser.userId,
								reqComment: "",
								resourceType: "menu",
								resourceKey: this.newMenu.menuIdx,
							};

							let match = data.find((v) => v.locale == trans.locale);

							trans.transKey = this.newMenu.title;
							if (this.isEmpty(trans.transStr)) trans.transStr = this.newMenu.title;

							return Object.assign({ locale: v.locale, flag: v.flag, code: v.code }, trans, match);
						});

						this.transStrs.range(list);
					});
				} else {
					let list = this.nations.map((v) => {
						let trans = {
							transKey: "",
							locale: v.locale,
							transStr: "",
							status: "Done",
							reqDt: new Date().format("yyyyMMddHHmmss"),
							reqUserId: this.$store.getters.loginUser.userId,
							reqComment: "",
							resourceType: "menu",
							resourceKey: this.newMenu.menuIdx,
						};

						trans.transKey = this.newMenu.title;

						return Object.assign({ locale: v.locale, flag: v.flag, code: v.code }, trans);
					});

					this.transStrs.range(list);
				}
			},
			onChangePath(path) {
				if (path.includes("/visual/analysis/")) {
					this.newMenu.resourceType = "analysis";
					this.newMenu.resourceKey = path.split("/visual/analysis/")[1];
				} else if (path.includes("/board/boardList/")) {
					this.newMenu.resourceType = "bbs";
					this.newMenu.resourceKey = path.split("/board/boardList/")[1];
				}
			},
			onChangeIcon(value) {
				this.newMenu.icon = null;
				if (!this.isEmpty(value)) this.newMenu.icon = value.icon;
				else this.newMenu.icon = "";
			},
			onSaveMenu() {
				this.isUpdate ? this.updateMenu() : this.insertMenu();
			},
			insertMenu() {
				let menu = Object.assign(this.newMenu, { parentMenuIdx: this.parentMenu.menuIdx, children: [] });

				let message = this.isEmpty(menu.path) ? popupMessages.PERMISSION_MENU_SAVE_POPUP_MESSAGE : popupMessages.PERMISSION_MENU_ADD_POPUP_MESSAGE;

				this.alertQuestion(message).then((result) => {
					if (!result.value) return;

					backEndApi.menu
						.insertMenu(menu)
						.then(({ data }) => {
							if (this.$err(data)) return;

							// 부모노드가 있고 부모노드가 그룹에 포함되어있을경우
							if (data && data.parentMenuIdx && !this.isEmpty(this.groupMenus.find((v) => v.menuIdx == data.parentMenuIdx))) {
								let menus = [{ groupIdx: this.curGroupIdx, menuIdx: data.menuIdx, text: data.title }];

								// console.log('MenuDetail.vue menus - ', menus);
								backEndApi.group.insertGroupMenu(menus).then(({ data }) => {
									if (this.$err(data)) return;

									this.$store.dispatch("UPDATE_MENUS");
								});
							}

							// 다국어
							if (data && data.menuIdx) {
								let transArr = this.transStrs.map((v) => {
									v.transKey = menu.title;
									v.status = "Done";
									v.reqDt = new Date().format("yyyyMMddHHmmss");
									v.reqUserId = this.$store.getters.loginUser.userId;
									v.reqComment = "";
									v.resourceType = "menu";
									v.resourceKey = data.menuIdx;
									return v;
								});

								// TODO: 트랜잭션
								backEndApi.trans.upsertTransByResourceKey("menu", data.menuIdx, transArr).then(({ data }) => {
									if (this.$err(data)) return;
								});
							}
						})
						.then(() => {
							this.$store.dispatch("UPDATE_MENUS");
							this.alertNoti(popupMessages.PERMISSION_COMMON_REGIST_SUCCESS_POPUP_MESSAGE); // 메뉴가 추가되었습니다.
							this.$emit("save-done");
						});
				});
			},
			updateMenu() {
				let message = this.isEmpty(this.newMenu.path) ? popupMessages.PERMISSION_MENU_SAVE_POPUP_MESSAGE : popupMessages.PERMISSION_MENU_UPDATE_POPUP_MESSAGE;

				this.alertQuestion(message).then((result) => {
					if (!result.value) return;

					let menu = Object.assign({}, this.newMenu);
					delete menu.children;
					delete menu.tb_menus;

					backEndApi.menu.updateMenu(menu).then(({ data }) => {
						if (this.$err(data)) return;

						// 다국어
						let transArr = this.transStrs.map((trans) => {
							trans.transKey = menu.title;
							trans.reqDt = new Date().format("yyyyMMddHHmmss");
							trans.reqUserId = this.$store.getters.loginUser.userId;
							trans.status = "Done";
							return trans;
						});

						// TODO: 트랜잭션
						backEndApi.trans.upsertTransByResourceKey("menu", menu.menuIdx, transArr).then(({ data }) => {
							if (this.$err(data)) return;
						});

						this.$store.dispatch("UPDATE_MENUS");
						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			onDeleteMenu() {
				this.alertConfirmWarning(popupMessages.PERMISSION_MENU_DELETE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.menu.deleteMenu(this.changeMenu.menuIdx).then(({ data }) => {
						if (this.$err(data)) return;

						// 다국어  // TODO: 트랜잭션
						backEndApi.trans.deleteTransByResourceKey("menu", this.changeMenu.menuIdx);

						this.$store.dispatch("UPDATE_MENUS");
						this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>
