<template>
  <div v-xe-pm.E.A>
    <!-- <title-icon :title="$t('설정')" icon="fa-cog" /> -->
    <header-box :title="$t('권한 관리')"/>
    <hr style="margin: 10px 0px 10px 0px" />

    <div class="row">
      <div class="col-12">
        <group-mgmt @select-group="onSelectedGroup" />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <menu-tree :curGroupIdx="curGroupIdx" />
      </div>
      <div class="col-8">
        <panel :variant="panelVariant" bodyClass="p-0" hideExpand="true" hideReload="true" hideCollapse="true" hideRemove="true">
          <template slot="header">
            <span class="panel-title"><trans>사용자</trans></span>
          </template>
          <user-mgmt :curGroupIdx="curGroupIdx" />
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import GroupMgmt from "./group/GroupMgmt.vue";
import MenuTree from "./menu/MenuTree.vue";
import UserMgmt from "./user/UserMgmt.vue";
import HeaderBox from "../../component/headerBox/sys/Header.vue";

export default {
  components: {
    GroupMgmt,
    MenuTree,
    UserMgmt,
    HeaderBox
  },
  data() {
    return {
      curGroupIdx: 0, // 선택된 그룹
    };
  },
  methods: {
    onSelectedGroup(group) {
      this.curGroupIdx = group.groupIdx;
    },
  },
};
</script>
